<template>
  <div class="container center" style="margin-top: 50px">

    <div class="form-group">
      <label>{{
          this.$route.query.language === 'en' ? 'What can we do better?' : 'Que pouvons-nous faire de mieux?'
        }}</label>
      <b-textarea v-model="feedback" rows="10"></b-textarea>
    </div>

    <b-row style="margin: 0">
      <b-button class="nextBtn" @click="goBack">{{
          this.$route.query.language === 'en' ? 'Go Back' : 'Retour en arrière'
        }}
      </b-button>
      <b-button :disabled="!enableButton()" style="margin-left: auto"
                v-bind:class="{'btn-enabled': enableButton()}"
                class="nextBtn" @click="submit">
        <b-spinner v-if="showLoader" small label="Small Spinner"></b-spinner>
        {{ this.$route.query.language === 'en' ? 'Send' : 'Envoyer' }}
      </b-button>
    </b-row>

  </div>
</template>

<script>

const axios = require('axios');

export default {
  data() {
    return {
      feedback: '',
      showLoader: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    enableButton() {
      return this.feedback.length > 0;
    },
    async submit() {
      this.showLoader = true;
      const url = `${process.env.VUE_APP_BASE_URL}/submitFeedback`;
      const response = await axios.post(url, {feedback: this.feedback, token: this.$route.query.token});
      if (response && response.data) {
        this.showLoader = false;
        this.$router.push({
          name: 'feedback-success',
          query: {
            token: this.$route.query.token,
            language: this.$route.query.language,
            storefront: this.$route.query.storefront
          }
        });
      }
    },
    timer() {
      this.showLoader = false;
    }
  },
  mounted() {
  }
}

</script>

<style>

.btn-enabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
